


















import sink from '@/sink';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  watch,
} from '@vue/composition-api';
import { InlineLoader } from '@/shared';
import { ReportActionsMounted, ReportSelected } from '@/reporting/+state';

export default defineComponent({
  components: { InlineLoader },
  setup(props, context) {
    onMounted(sink.lazyBroadcast(ReportActionsMounted()));

    const currentReportId = sink.select('report.id');

    const stopWatching = watch(currentReportId, (value, oldValue) => {
      if (value !== oldValue) {
        if (value?.length) {
          context.root.$router.replace(`/reporting/${value}`);
        } else {
          context.root.$router.replace(`/reporting`);
        }
      }
    });

    onBeforeUnmount(() => {
      stopWatching();
    });

    return {
      currentReportId,
      loadingAvailableReports: sink.select('reports.available-loading'),
      availableReportsOptions: sink.select('reports.available-options'),
      reportIdChanged: reportId => {
        sink.broadcast(ReportSelected({ reportId }));
      },
    };
  },
});
