








import { defineComponent } from '@vue/composition-api';
import Actions from './Actions.vue';
import Filters from './Filters.vue';
import Report from './Report.vue';
import { ListPageLayout } from '@/shared';

export default defineComponent({
  components: { ListPageLayout, Actions, Filters, Report },
});
