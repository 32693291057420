





























import { computed, defineComponent, PropType } from '@vue/composition-api';
import { DynamicFilter } from '@/shared/models/dynamic-filter.interface';
import DatePicker from './DatePicker.vue';

export default defineComponent({
  props: {
    filters: {
      type: Array as PropType<DynamicFilter[]>,
    },
    values: {
      type: Object as PropType<Record<string, string>>,
    },
  },
  components: { DatePicker },
  setup(props) {
    return {
      hasFilters: computed(() => !!props.filters?.length),
    };
  },
});
