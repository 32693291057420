<template>
  <iframe
    v-if="computedModeUrl"
    :id="embedId"
    :src="computedModeUrl"
    :data-report="reportId"
    ref="iframeRef"
    class="mode-report-iframe"
  ></iframe>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { debounce, generateUUID } from './mode-embed';

export default defineComponent({
  props: ['url', 'reportId'],
  setup(props) {
    const embedId = ref('');
    const iframeRef = ref(null);

    const onMessage = event => {
      if (iframeRef.value && event.data && event.data.id === embedId.value) {
        // footer = 24 height + 1 border | iframe = 2 border
        // Find the element again since if a framework like angular compiles our iframe, we'll be pointing to a placeholder frame
        iframeRef.value.style.height = event.data.height * 1 + (2 + 24) + 'px';
      }
    };

    window.addEventListener('message', onMessage, false);

    window.addEventListener(
      'resize',
      debounce(function() {
        if (
          typeof iframeRef?.value?.contentWindow?.postMessage !== 'function'
        ) {
          /* [dw] Reviewers: This is one console.info I'd like to keep for when it gets to the dev environment so
           *      we see when eventing isn't working once the reports actually load. It can be removed once its working.
           */
          console.info(
            '[ModeReportView] Not passing resize update to iframe, postMessage not function',
          );
          return;
        }

        iframeRef.value.contentWindow.postMessage({
          name: 'getNewSize',
          current: {
            height: iframeRef.value.offsetHeight,
            width: iframeRef.value.offsetWidth,
          },
        });
      }, 250),
    );

    return {
      embedId,
      iframeRef,
      computedModeUrl: computed(() => {
        if (!props.url?.length) {
          return null;
        }

        try {
          const computedUrl = new URL(props.url);
          const computedParams = new URLSearchParams(computedUrl.searchParams);
          computedParams.append('resize', 'true');

          embedId.value = generateUUID();
          computedParams.append('embed_id', embedId.value);
          computedUrl.search = computedParams.toString();

          return computedUrl.toString();
        } catch (e) {
          console.warn('[ModeReportView] error while computing new URL', e);
        }

        return null;
      }),
    };
  },
});
</script>

<style scoped>
.mode-report-iframe {
  border: none;
  width: 100%;
  height: 300px;
}
</style>
