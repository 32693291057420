










import { defineComponent } from '@vue/composition-api';
import sink from '@/sink';
import { ModeReportView } from '@conversa/bedazzled/src/reports';

export default defineComponent({
  components: { ModeReportView },
  setup() {
    return {
      currentReportId: sink.select('report.id'),
      currentReportUrl: sink.select('report.url'),
      loadingReport: sink.select('report.loading'),
    };
  },
});
