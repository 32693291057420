


















import { ListPageFilters } from '@/shared';
import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import {
  ReportFiltersChanged,
  ReportFiltersCleared,
  ReportRunRequested,
} from '@/reporting/+state';
import DynamicFilters from '@/shared/components/DynamicFilters.vue';

export default defineComponent({
  components: {
    ListPageFilters,
    DynamicFilters,
  },
  setup() {
    return {
      filtersRaw: sink.select('report.filters-raw'),
      filterValues: sink.select('report.filters'),
      loadingReports: sink.select('report.loading'),
      onRun: sink.lazyBroadcast(
        ReportRunRequested({ runType: 'filterUpdate' }),
      ),
      onClearAll: sink.lazyBroadcast(ReportFiltersCleared()),
      onFilterChanged(event: { input: string; value: string }) {
        sink.broadcast(ReportFiltersChanged(event));
      },
    };
  },
});
